import { FC } from 'react';

import { PageFrame } from '@rikstv/play-common/src/components/pageFrame/PageFrame';
import { Link } from '@rikstv/play-common/src/router/Link';
import { errorTracker } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import { Body } from '@rikstv/shared-components';

import { shouldAttemptRedirectToLowerCasePath } from '../../../hacks/caseSensitiveCloudfrontHack';
import routerPath from '../../router/router.path';

export const NotFound: FC = () => {
  // can end up here for valid but case-different urls that should hit WebApp
  // the issue being that CloudFront Routing is case sensitive...
  if (shouldAttemptRedirectToLowerCasePath()) {
    window.location.pathname = window.location.pathname.toLowerCase();
    return null;
  }

  // report 404 to Google Analytics
  window.dataLayer && window.dataLayer.push({ event: '404page' });
  errorTracker.capture404NotFound();

  return (
    <PageFrame tallHeader title="Vi beklager! Vi fant ikke siden du lette etter." meta="noindex" centerPadding>
      <Body>
        Gå til <Link to={routerPath.frontpage()}>forsiden</Link> for en frisk start, eller du kan besøke våre{' '}
        <Link to={routerPath.help()}>hjelpesider</Link> dersom du står fast på noe.
      </Body>
    </PageFrame>
  );
};
