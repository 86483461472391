import { CSSProperties, FC } from 'react';

import config from '@rikstv/play-common/src/config';

import { Breakpoints } from '../../grid/breakpoints';
import { SwimlaneSettings } from '../Swimlane.types';

import { StandardItem, StandardItemProps } from './StandardItem';

import styles from './NumberedItem.module.css';

export const numberedConfig: SwimlaneSettings = {
  placeholderHeight: {
    [Breakpoints.XS]: 250,
    [Breakpoints.SM]: 350,
    [Breakpoints.MD]: 325,
    [Breakpoints.ML]: 390,
    [Breakpoints.LG]: 310,
    [Breakpoints.XL]: 320,
    [Breakpoints.XXL]: 370,
    [Breakpoints.DEFAULT]: 340,
  },
};

type NumberedItemProps = StandardItemProps & { index: number };

export const NumberedItem: FC<NumberedItemProps> = props => {
  //Different line-height is needed for the different brands
  const lineHeight = config.application === 'Strim' ? '0.65' : '0.75';
  const style: CSSProperties & { [key: string]: string | number } = props.portrait
    ? { '--font-size': 'clamp(150px, 12vw, 213px)', lineHeight }
    : { '--font-size': 'clamp(125px, 10vw, 200px)', lineHeight };

  return (
    <div className={styles.container}>
      <p style={style} className={styles.number}>
        {props.index}
      </p>

      <StandardItem {...props} isHeroPortrait />
    </div>
  );
};
