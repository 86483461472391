import React, { CSSProperties, FC, useEffect, useReducer, useRef, useState } from 'react';
import classNames from 'classnames';

import { Channel, Program } from '@rikstv/play-common/src/forces/channels/channel.types';
import { Tag } from '@rikstv/shared-components';

import { secondsFormatted, timeFormatted } from '../../utils/dateTimeUtil';
import { programFilter } from '../programPoints/ProgramPoints';
import { HoveredProgram, ProgramStartMarkers } from '../ProgramStartPoints/ProgramStartMarkers';
import { ThumbnailPreview } from '../ThumbnailPreview/ThumbnailPreview';

import {
  DRAG,
  START_DRAG,
  START_HOVER,
  STOP_DRAG,
  STOP_HOVER,
  UPDATE_CURRENT_TIME,
  UPDATE_DURATION,
  UPDATE_LIVE,
} from './Actions';
import { getRect, percentage } from './progressBar.utils';
import { reducer } from './reducer';

import styles from './ProgressBar.module.css';

interface Props {
  currentTime: number;
  streamStartSeconds: number;
  streamEndSeconds: number;
  insideVideoPlayer?: boolean;
  isLinear: boolean;
  isCasting?: boolean;
  onSetProgressFromStartBetween0and1: (update: number) => void;
  programs?: Program[];
  thumbnailTextTrack?: THEOplayer.TextTrack;
  hideCursor?: boolean;
}

const initialState = {
  hover: false,
  dragging: false,
  dragX: 0,
  time: '',
  totalTime: '',
  width: 0,
  isLinear: false,
};

export const ProgressBar: React.FC<Props> = ({
  currentTime,
  insideVideoPlayer = true,
  onSetProgressFromStartBetween0and1,
  isLinear,
  streamStartSeconds,
  streamEndSeconds,
  isCasting = false,
  programs,
  thumbnailTextTrack,
  hideCursor,
}) => {
  const [hoveredProgram, setHoveredProgram] = useState<HoveredProgram | undefined>(undefined);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    currentTime,
    isLinear: isLinear,
    duration: streamEndSeconds - streamStartSeconds,
  });

  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch({
      type: UPDATE_CURRENT_TIME,
      value: { currentTime, streamStartSeconds, streamEndSeconds },
    });
  }, [currentTime, streamEndSeconds, streamStartSeconds]);

  useEffect(() => {
    dispatch({
      type: UPDATE_DURATION,
      value: { currentTime, streamStartSeconds, streamEndSeconds },
    });
  }, [streamStartSeconds, streamEndSeconds, currentTime]);

  useEffect(() => {
    dispatch({ type: UPDATE_LIVE, value: isLinear });
  }, [isLinear]);

  const showProgress = state.hover || state.dragging;

  const handleKeyDown = () => {};
  const programTitleOffset = hoveredProgram ? `-${Math.ceil(hoveredProgram.title.length * 4.5)}px` : '0px';

  const progressBarRect = getRect(progressBarRef);

  return (
    <div
      role="progressbar"
      tabIndex={0}
      className={classNames(styles.progressControlContainer, { [styles.noCursor]: hideCursor })}
      ref={progressBarRef}
      onKeyDown={handleKeyDown}
      onMouseEnter={e => dispatch({ type: START_HOVER, value: e.clientX - progressBarRect.x })}
      onMouseLeave={() => dispatch({ type: STOP_HOVER })}
      onMouseDown={e => {
        dispatch({ type: START_DRAG, value: e.clientX - progressBarRect.x });
      }}
      onMouseMove={e => {
        const rect = progressBarRect;
        dispatch({
          type: DRAG,
          value: {
            offsetX: e.clientX - rect.x,
            percentage: percentage(e.pageX, rect.x, rect.width),
          },
        });
      }}
      onMouseUp={e => {
        const rect = progressBarRect;
        dispatch({ type: STOP_DRAG, value: percentage(e.pageX, rect.x, rect.width) });
      }}
      onClick={event => {
        const rect = progressBarRect;
        const progressFromStartBetween0and1 = percentage(event.pageX, rect.x, rect.width);
        onSetProgressFromStartBetween0and1(progressFromStartBetween0and1);
      }}>
      <ThumbnailPreview
        thumbnailTextTrack={thumbnailTextTrack}
        hover={state.hover}
        duration={state.duration}
        streamStartSeconds={streamStartSeconds}
        dragX={state.dragX}
        progressBarRef={progressBarRef}
        hoveredProgramImageUrl={hoveredProgram?.imageUrl}
      />
      {programs && (
        <ProgramStartMarkers
          programs={programs}
          setHoveredProgram={setHoveredProgram}
          streamDuration={state.duration}
        />
      )}
      <div
        className={styles.progressControl}
        data-current-time={state.time}
        data-tooltip={insideVideoPlayer && !isCasting ? state.totalTime : ''}>
        <div
          className={styles.hoverProgress}
          style={{ width: state.width, display: showProgress ? 'block' : 'none' }}
        />
        <div className={styles.playProgress} style={{ width: state.dragging ? state.dragX + 'px' : state.width }}>
          <div
            className={classNames(styles.progressKnob, 'with-keyboard-focus-within')}
            style={{ display: showProgress ? 'block' : 'none' }}
          />
        </div>
      </div>
      <div
        className={styles.mouseTime}
        data-tooltip={hoveredProgram?.title ?? state.time}
        style={
          {
            left: Math.min(progressBarRect.width - 35, Math.max(35, state.dragX)) + 'px',
            '--program-title-offset': programTitleOffset,
          } as CSSProperties
        }
      />
    </div>
  );
};

interface ProgressBarWithTimeProps extends Props {
  duration: number;
  channel?: Channel;
}

export const ProgressBarWithTime: FC<ProgressBarWithTimeProps> = ({
  currentTime,
  onSetProgressFromStartBetween0and1,
  streamStartSeconds,
  streamEndSeconds,
  duration,
  isLinear,
  isCasting = false,
  channel,
  thumbnailTextTrack,
  hideCursor,
}) => {
  // Normalize currentTime for dynamic vod assets where streamStartSeconds > 0
  // Also handles startup state where streamStart is set to a high value but currentTime is still 0
  const normalizedTime = streamStartSeconds >= currentTime ? 0 : Math.abs(currentTime - streamStartSeconds);
  const time = secondsFormatted(normalizedTime);
  // Avoids time <span> width-jitter since numbers are not equally wide
  const timeChWidth = `${time.length + 1}ch`;
  const totalTime = isLinear ? timeFormatted(new Date()) : secondsFormatted(duration);
  const programs = channel ? channel.programs.filter(p => programFilter(channel, p)) : undefined;

  return (
    <div className={styles.progressbarTime}>
      <Tag className={styles.progressbarTimeWrapper}>
        {!isLinear && <div style={{ width: timeChWidth, paddingRight: '1rem' }}>{time}</div>}
      </Tag>
      <ProgressBar
        currentTime={currentTime}
        isLinear={isLinear}
        streamStartSeconds={streamStartSeconds}
        streamEndSeconds={streamEndSeconds}
        onSetProgressFromStartBetween0and1={onSetProgressFromStartBetween0and1}
        isCasting={isCasting}
        programs={programs}
        thumbnailTextTrack={thumbnailTextTrack}
        hideCursor={hideCursor}
      />
      <Tag className={styles.progressbarTimeWrapper}>
        {(!isLinear || isCasting) && (
          <div className={styles.progressbarTotalTime} style={{ width: timeChWidth, paddingLeft: '1rem' }}>
            {(!isLinear || isCasting) && totalTime}
          </div>
        )}
      </Tag>
    </div>
  );
};
