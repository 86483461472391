import { DeepLinkClipboardButton } from '@rikstv/play-common/src/components/deepLinkClipboardButton/DeepLinkClipboardButton';
import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import { LayoutBase } from './LayoutBase';

export const LayoutDarker: FCC<{
  hasFooter?: boolean;
}> = ({ children, ...rest }) => {
  return (
    <LayoutBase theme="themeDarker" hasMiniController={true} minKontoLayout {...rest}>
      <DeepLinkClipboardButton />
      {children}
    </LayoutBase>
  );
};
