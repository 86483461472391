/* eslint-disable react/forbid-elements, no-restricted-imports, jsx-a11y/anchor-has-content */
import React from 'react';
import { Link as ReactRouterLink, NavLink as ReactRouterNavLink } from 'react-router-dom';

import { isUrlSameDomain, isUrlSameHost } from '../utils/url/url.utils';

import { isExternal, isMailTo } from './router.utils';

type Props = {
  to: string;
  withDefaultStyle?: boolean;
  activeClassName?: string;
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;

export const Link = ({ to, className = '', withDefaultStyle = true, activeClassName, ...props }: Props) => {
  const cName = `${className} ${withDefaultStyle ? 'rds-link' : ''}`;
  if (isMailTo(to)) {
    return <a className={cName} href={to} {...props} rel="noreferrer" />;
  }
  if (isExternal(to) || props.download != null) {
    if (isUrlSameHost(to)) {
      return <a className={cName} href={to} target={'_self'} {...props} />;
    }
    const referrerProps = isUrlSameDomain(to)
      ? ({ referrerPolicy: 'no-referrer-when-downgrade' } as const)
      : { rel: 'noreferrer' };
    // Allow links on same domain to get full referrer with path so we can track movement between pages/apps.
    // Especially important for play.rikstv.no -> minside.rikstv.no
    // eslint-disable-next-line react/jsx-no-target-blank
    return <a className={cName} href={to} target={'_blank'} {...referrerProps} {...props} />;
  }
  if (activeClassName) {
    return (
      <ReactRouterNavLink
        className={({ isActive }) => `${cName} ${isActive ? activeClassName : ''}`}
        to={to}
        {...props}
      />
    );
  }
  return <ReactRouterLink className={cName} to={to} {...props} />;
};

type UnstyledProps = Omit<Props, 'withDefaultStyle'>;

export const UnstyledLink: React.FC<UnstyledProps> = props => <Link {...props} withDefaultStyle={false}></Link>;
