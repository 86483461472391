import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import config from '@rikstv/play-common/src/config';
import { CastMiniControls } from '@rikstv/play-player/src/chromecast/CastMiniControls/CastMiniControls';

import { Chatbot } from '../../../utils/chatbot/Chatbot';
import { Footer } from '../../components/footer/Footer';
import { Navigation } from '../../components/header/Navigation';

import style from './Layout.module.css';
type Theme = Extract<keyof typeof style, `theme${string}`>;

interface Props {
  hasMiniController?: boolean;
  hasFooter?: boolean;
  children?: ReactNode;
  theme: Theme;
  minKontoLayout?: boolean;
}

export const LayoutBase: FC<Props> = ({
  hasMiniController,
  hasFooter = true,
  children,
  theme,
  minKontoLayout = false,
}) => {
  return (
    <div className={`${style.layout} ${style[theme]} `}>
      <Helmet defaultTitle={config.basePageTitle} titleTemplate="%s | Strim">
        {config.isDevelopment && <meta name="robots" content="noindex" />}
        <meta property="og:url" content={window.location.href}></meta>
      </Helmet>
      <Navigation />
      <main id="main" className={`${style.main} ${minKontoLayout ? style.centerLayout : ''}`} tabIndex={-1}>
        <div className={minKontoLayout ? style.centerMain : ''}>
          {children}
          {hasMiniController && <CastMiniControls />}
        </div>
      </main>
      {hasFooter && <Footer />}
      <Chatbot />
    </div>
  );
};
